import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"
import icon5 from "../images/5_736_1128.jpg"
// import { Link } from "gatsby"

const handleSubmit = event => {
  // event.preventDefault()
  alert(`Mesajul dvs. a fost trimis.`)
}

export default () => (
  <Layout>
    <SEO
      title="Formular contact | Telecommunication Networks SRL"
      description="Solicitați oferta pentru execuție rețea fibră optică. Telecommunication Networks SRL este o companie românească cu filială
          în Franța ce execută lucrări de construcții rețele de fibră optică,
          întreținere a rețelelor de cablu și instalare echipamente de
          telecomunicații."
    />
    <Content>
      <h1>Contact</h1>
      <div className="alert alert-info" role="alert">
        Ne puteți contacta și prin aplicația{" "}
        <a
          className="alert-link"
          href="https://wa.me/+40740142026"
          target="_blank"
          rel="noopener noreferrer"
        >
          WhatsApp +40740142026
        </a>{" "}
        ing. Valentin Rădulescu.
      </div>
      <h5>Formular contact</h5>

      <div className="row">
        <div className="col-md-8">
          <form
            method="POST"
            action="https://formspree.io/tcnetworks2018@gmail.com"
            onSubmit={handleSubmit}
          >
            <input type="text" name="_gotcha" style={{ display: "none" }} />
            <div className="form-group">
              <label htmlFor="fullName">Nume</label>
              <input
                type="name"
                name="name"
                className="form-control"
                id="name"
                placeholder=""
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Adresă email</label>
              <input
                type="email"
                name="_replyto"
                className="form-control"
                id="email"
                placeholder=""
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Telefon</label>
              <input
                type="text"
                name="phone"
                className="form-control"
                id="phone"
                placeholder=""
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Mesajul dvs.</label>
              <textarea
                name="message"
                className="form-control"
                id="message"
                rows="6"
              />
            </div>
            <button
              type="submit"
              value="Send"
              className="btn btn-primary"
              style={{
                backgroundColor: "#2CAF87",
                borderColor: "#2CAF87",
                marginBottom: "1rem",
              }}
            >
              Trimite
            </button>
          </form>
        </div>
        <div className="col-md-4 align-self-center">
          <img src={icon5} className="img-fluid" alt={""} title={""} />
        </div>
      </div>
    </Content>
  </Layout>
)
